@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: rgb(255, 255, 255);
}

* {
    box-sizing: border-box;
}

@font-face {
    font-family: 'OktaNeue-Normal';
    src: url('../public/assets/fonts/OktaNeue-Normal.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(0, 0, 0);
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}

.custom-prev-arrow {
    left: -25px;
}

.custom-next-arrow {
    right: -25px;
}

#popup {
    position: fixed;
    z-index: 1000;
}

.carousel-container {
    overflow: hidden;
    width: 100%;
}

.carousel-container img {
    width: 100%;
    height: auto;
    display: block;
}

.carousel-container img {
    width: 100%;
    height: auto;
    display: block;
}

.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}

.custom-prev-arrow {
    left: -50px;
}

.custom-next-arrow {
    right: -50px;
}